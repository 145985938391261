import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import Video from "../components/Video"
import PhotoGalleryDialog from "../components/dialogs/PhotoGalleryDialog"

import { storage, devices } from "../settings"

const Description = styled.p`
`
const Summary = styled.div`
    margin: 10px 0px;
`
const BlogContent = styled.div`
    margin: 10px 0px;
`
const MainImage = styled.div`
    img { 
        width: 100%;
        height: auto;
        border-radius: 4px;
        cursor: pointer;
        display: block;
    }
`

const Images = styled.div`
    > div {
        border-radius: 4px;
        margin: 0px 5px 5px 0px;
        cursor: pointer;

        @media ${devices.mobile} {
            width: 100px;
            height: 100px;
        }

        &:hover {
            transform: scale(1.025);
            box-shadow: 0 5px 5px rgba(0,0,0,0.3);
        }
    }
`

const Row = styled.div`
  display: flex;

  @media ${devices.mobile} {
    flex-flow: column;
  }

  div.left {
    flex-basis: 60%;
  }
  div.right {
      flex-basis: 40%;
      padding: 0px 0px 0px 15px;

      @media ${devices.mobile} {
       padding-left: 0px;
      }      
  }
`
const Links = styled.div`
  margin-top: 20px;

  > a {
      display: flex;
      align-items: center;      
      padding: 5px;
      text-decoration: none;
      margin-bottom: 4px;
      
    &:hover {
        background-color: #eee;
        text-dedcoration: underline;        
    }
  }
`

export default function BlogPostTemplate({ data }) {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);

    const post = data.posts.edges[0].post;
    const files = data.files.edges;
    const links = data.links.edges.map(l => l.link).filter(l => l.type == "url");
    const videos = data.links.edges.map(l => l.link).filter(l => l.type == "video");
    const images = files.filter(f => f.file.image_ind == 1);

    for (let image of images) {
        image.src = storage.url + image.file.version_url;
    }
        
    function toggleLightbox(index) {
        console.log("toggleLightbox", index)
        setSelected(index);
        setOpen(true);
    }

    return (
        <>
            <SEO page="blogpost" />
            <Page 
                breadcrumb={[
                    { url: "/", title: "Home"},
                    { url: "/blog/", title: "Blog"},
                    { url: null, title: post.title }
                ]}
            >
                <PageHeader 
                    title={post.title}  
                    subtitle={post.added_date_formatted}
                />

                <Row>
                    <div className="left">
                        {post.description && 
                            <Description>{post.description}</Description>
                        }
                        {(post.summary && post.summary != "<p><br></p>") && 
                            <Summary dangerouslySetInnerHTML={{ __html: post.summary }} />
                        }
                        {post.content && 
                            <BlogContent dangerouslySetInnerHTML={{ __html: post.content }} />
                        }
                    </div>
                    {(post.video_url || images.length > 0 || links.length > 0) && 
                        <div className="right">
                            {(videos.length > 0) && 
                                <>
                                    {videos.map((video, index) => (
                                        <Video key={index} video_url={video.url} />
                                    ))}
                                </>
                            }
                            {(videos.length == 0 && images.length > 0) && 
                                <Images>
                                    {images.map((image, index) => (
                                        <MainImage key={index} onClick={() => toggleLightbox(index)} className="grow">
                                            <GoogleImage 
                                                root 
                                                src={image.file.version_url || image.file.preview_url} 
                                                alt={image.file.title} 
                                                required 
                                                lazy 
                                            />
                                        </MainImage>
                                    ))}
                                </Images>
                            }    

                            {(links.length > 0) && 
                                <Links>
                                {links.map((link, index) => (
                                    <a key={index} href={link.url}>
                                        <GoogleImage root src="2020/downloadpdf.png" width={40} />
                                        &nbsp;
                                        &nbsp;
                                        {link.url}
                                    </a>
                                ))} 
                                </Links>                           
                            }                    
                        </div>
                    }
                </Row>                
            </Page>
            {open &&
                <PhotoGalleryDialog 
                    photos={images.map(i => {
                        return {
                            url: i.file.version_url,
                            title: i.file.title
                        }
                    })}
                    currentIndex={selected}
                    onClose={() => setOpen(false)}
                />
            }            
        </>
    )
}

export const query = graphql`
    query($name: String!) {
        posts: allMysqlBlogPosts(filter: {name: {eq: $name}}) {
            edges {
                post: node {
                    id
                    name
                    title
                    description
                    summary
                    content
                    keywords
                    added_date_formatted
                    preview_url
                }
            }
        }
        files: allMysqlBlogFiles(filter: {blog_name: {eq: $name}}) {
            edges {
                file: node {
                    id
                    filename
                    title
                    preview_url
                    version_url
                    image_ind
                }
            }
        }
        links: allMysqlBlogLinks(filter: {blog_name: {eq: $name}}) {
            edges {
                link: node {
                    id
                    type
                    url
                }
            }
        }        
    }`